@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/components';
@import 'styles/mui';

body {
  font-size: 1rem;
  font-family: 'Noto Sans Display' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 100vh;
  max-width: 600px;
  margin: auto;
  background: var(--color-primary);
  box-shadow: 0px 4px 4px rgba(10, 10, 10, 0.2);
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

:root {
  --color-primary: #0a2552;
  --color-primary-dark: #0a2552cc;
  --color-secondary: #ff8a9b;
  --color-secondary-dark: #ff4d67;
  --color-info: #7296ea;
  --color-info-dark: #506edd;
}

body > iframe {
  display: none;
}

#chat-widget-minimized {
  display: none !important;
}

@font-face {
  font-family: 'Lexend';
  src: url('/assets/fonts/Lexend-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Lexend';
  src: url('/assets/fonts/Lexend-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Lexend';
  src: url('/assets/fonts/Lexend-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lexend';
  src: url('/assets/fonts/Lexend-Thin.ttf') format('truetype');
  font-weight: 100;
}
