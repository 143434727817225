.MuiFormControl-root {
  > label {
    margin-bottom: 4px;
  }
  > .MuiFormHelperText-root {
    margin: 4px 0px;
  }
}

.MuiTextField-root {
  > label {
    font-size: 20px;
  }
  > .MuiInput-root {
    margin-top: 20px;
    &::before {
      border-color: #d3dbea;
    }
  }
  > .MuiFormHelperText-root {
    font-size: 14px;
  }
}

.MuiTableContainer-root {
  .MuiTableRow-root:hover {
    background-color: #00000006;
  }
  .MuiTableCell-head {
    padding: 12px 12px;
    font-weight: 500;
  }
  .MuiTableCell-body {
    padding: 12px 12px;
  }
}

.MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root {
  border-bottom: none;
}

.MuiTabs-root {
  margin: 4px 0px;

  .MuiTabs-flexContainer {
    gap: 16px;
    padding: 0px 12px;
  }

  .MuiTab-root {
    color: #fff;
    font-size: 16px;
    min-height: 40px;
    border-radius: 20px;
    padding: 8px 16px;
    &.Mui-selected {
      background-color: #fff;
      color: #67707b;
      box-shadow: 0px 4px 4px rgba(10, 10, 10, 0.2);
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.MuiLoadingButton-root {
  .MuiLoadingButton-loadingIndicator {
    position: static;
    left: 0px;
    margin-left: 4px;
    color: #fff9 !important;
  }

  &.MuiLoadingButton-loading {
    color: #fff9 !important;
  }
}

.MuiButton-root.MuiButton-text,
.MuiButton-root.MuiButton-contained {
  &:not(.MuiButtonGroup-grouped) {
    border-radius: 12px;
  }
  &:hover {
    filter: brightness(0.9);
  }
  &.MuiButton-containedPrimary {
    @extend .bg-primary-gradient;
  }
  &.MuiButton-containedSecondary {
    @extend .bg-secondary-gradient;
  }
  &.MuiButton-containedInfo {
    @extend .bg-info-gradient;
  }
  > .MuiLoadingButton-loadingIndicator {
    color: #333;
  }
}

.MuiDialog-root {
  > .MuiBackdrop-root {
    background-color: #000c;
  }
  > .MuiDialog-container .MuiDialog-paper {
    max-width: 480px;

    .MuiDialogTitle-root {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 14px 24px;
    }
    .MuiDialogActions-root {
      justify-content: center;
      padding-bottom: 20px;
    }
  }
  > .MuiDialog-container .MuiDialog-paper:not(.MuiDialog-paperFullScreen) {
    border-radius: 24px;
  }
  > .MuiDialog-container .MuiDialog-paper.MuiDialog-paperFullScreen {
    max-width: 600px;
    background: url(../assets/images/App.Background.png) no-repeat center / cover;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root {
  background-color: #fffb;
  border-radius: 100px;
  > .MuiOutlinedInput-input {
    padding: 12px 16px;
  }
  > .MuiOutlinedInput-notchedOutline {
    border-radius: 100px;
  }
}

.SnackbarContent-root {
  max-width: 560px;

  .SnackbarItem-message {
    font-size: 16px;
    font-family: 'Noto Sans Display';
    .MuiSvgIcon-root {
      width: 22px;
      height: 22px;
    }
  }
}
