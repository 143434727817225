.bg-primary-gradient {
  background: linear-gradient(180deg, var(--color-primary) 0%, var(--color-primary-dark) 100%) !important;
  color: white !important;
}

.bg-secondary-gradient {
  background: linear-gradient(180deg, var(--color-secondary) 0%, var(--color-secondary-dark) 100%);
  color: white;
}

.bg-info-gradient {
  background: linear-gradient(180deg, var(--color-info) 0%, var(--color-info-dark) 100%) !important;
  color: white !important;
}

.bg-success-gradient {
  background: linear-gradient(180deg, #80d078 0%, #50b046 100%);
  color: white;
}

.bg-warning-gradient {
  background: linear-gradient(180deg, #ff9e7e 0%, #ff5018 100%);
  color: white;
}

.bg-grey-gradient {
  background: linear-gradient(180deg, #ffffff 0%, #d6d6d6 100%);
  color: #67707b !important;
}
